<style scoped></style>

<template>
  <div>
    <r-card v-for="(item, index) in certs" :key="item.name">
      <span slot="title" style="display: flex; align-items: center;">
        <fm-checkbox style="margin-left: 10px;" size="large" @click.native="() => itemCheckedAll(item, index)" :value="item.checked" :label="index" v-if="printer">
          {{item.label}}
        </fm-checkbox>
        <template v-else>
          {{item.label}}
        </template>
      </span>
      <r-imgs v-if="item.sources" :checked="item.checkedList" @checked="(list) => onImgChecked(item, index, list)" :checkbox="printer" :covers="item.sources.covers" :previews="item.sources.previews" :name="item.sources.name" />
    </r-card>
  </div>
</template>

<script>
import RCard from '../card'
import RImgs from '../imgs'
import { certs } from '../../libs'

export default {
  components: { RCard, RImgs },
  props: {
    printer: { type: Boolean, default: false }
  },
  data () {
    return {
      certs: [],
      loading: {
        load: true
      }
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  methods: {
    checkAll () {
      if (this.loading.load) {
        this.$notice.warning('请等待所有数据加载完成')
      } else {
        this.certs = this.certs.map(item => {
          item.checked = true
          item.checkedList = item.sources.covers.map((v, i) => i)
          return item
        })
      }
    },
    checkReverse () {
      this.certs = this.certs.map(item => {
        item.checked = false
        item.checkedList = []
        return item
      })
    },
    itemCheckedAll (item, index) {
      item.checked = !item.checked

      if (item.checked) {
        item.checkedList = item.sources.covers.map((v, i) => i)
      } else {
        item.checkedList = []
      }

      this.certs.splice(index, 1, item)
    },
    onImgChecked (item, index, list) {
      item.checkedList =  list
      item.checked =  list.length > 0
      this.certs.splice(index, 1, item)
    },
    async loadData () {
      this.loading.load = true
      this.$emit('loading')
      this.certs = certs.map(v => {
        return {
          label: v.label,
          name: v.name,
          sources: null,
          checked: false,
          checkedList: []
        }
      })

      const promise = certs.map(async (v, i) => {
        this.certs[i].sources = await v.get(this.id)
        this.certs.splice(i, 1, this.certs[i])
      })
      
      this.$Loading.start()
      await Promise.all(promise)
      this.$Loading.finish()
      this.loading.load = false
      this.$emit('loaded')
    }
  },
  mounted () {
    this.loadData()  
  }
}
</script>
