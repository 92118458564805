import { render, staticRenderFns } from "./preview.vue?vue&type=template&id=4bcda33f&scoped=true&"
import script from "./preview.vue?vue&type=script&lang=js&"
export * from "./preview.vue?vue&type=script&lang=js&"
import style0 from "./preview.vue?vue&type=style&index=0&id=4bcda33f&prod&scoped=true&lang=less&"
import style1 from "./preview.vue?vue&type=style&index=1&id=4bcda33f&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcda33f",
  null
  
)

export default component.exports